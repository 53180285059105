import React, { useState, useEffect } from 'react';
import { RetainQueryLink } from '@/ui/shared/components/Next/Link';
import Head from 'next/head';
import LoginContext, { useLoginContext } from '@/users/application/Session/LoginContext';
import { useSessionContext } from '@/users/application/Session/SessionContext';

import Button, { variantTypes } from '@/ui/shared/components/Button';
import UsersContent from '@/ui/shared/components/Layout/UsersContent';
import contentStyles from '@/ui/shared/components/Layout/UsersContent/usersContent.scss';
import FinalForm from '@/ui/shared/components/Form/FinalForm';
import EmailInput from '@/ui/shared/components/Form/Fields/EmailInput';
import PasswordInput from '@/ui/shared/components/Form/Fields/PasswordInput';
import TermsOfUse from '@/ui/shared/components/Templates/TermsOfUse';
import NeedAnAccount from './NeedAnAccount';
import validate from './validator';
import { TFinalFormRenderProp } from '@/ui/shared/components/Form/FinalForm/interfaces';
import useSSOValidation from '@/users/ui/helpers/useSSOValidation';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';

import styles from './loginStyles.scss';

const LOGIN_FORM_NAME = 'loginForm';

const Login = () => {
  const { session, currentUser, initialized } = useSessionContext();
  const { login, transferring, transferExistingSession } = useLoginContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const checkIsSSODomain = useSSOValidation();

  const handleSubmit = (values: object) => {
   setIsSubmitting(true);

   return login(values).catch(() => {
     setIsSubmitting(false);
   });
  };

  useEffect(() => { // Lets try to transfer existing session instead of asking for creds again
   if (
     initialized &&
     session &&
     currentUser
   ) {
      transferExistingSession();
    }
  }, [
    initialized,
    session?.ssid,
    currentUser?.id,
  ]);

  const renderFields: TFinalFormRenderProp = ({ invalid }, { Field }) => (
    <>
      <Field
        component={ EmailInput }
        name="email"
        dataTest="email"
        placeholder="Corporate Email"
        inputClassName={ styles.formInput }
        validate={ checkIsSSODomain }
        isLastPassAutofillEnabled
      />
      <Field
        component={ PasswordInput }
        dataTest="password"
        name="password"
        inputClassName={ styles.formInput }
        isLastPassAutofillEnabled
      />
      <Button
        variant={ variantTypes.firm }
        type="submit"
        disabled={ invalid || isSubmitting }
        title="Log in with FINSIGHT"
        block
        dataTest="logInWithFinsightButton"
      />
      <TermsOfUse />
      <RetainQueryLink
        className={ contentStyles.bottomLink }
        href="/forgot_password"
      >
        Forgot your password?
      </RetainQueryLink>
    </>
  );

  return initialized && !session && !transferring && (
    <>
      <Head>
        <title>Finsight | Login</title>
      </Head>
      <UsersContent
        title="Log In"
        subtitle={ (
          <>
            Allocate, Deal Roadshow, DealVDR, Evercall, VeriSend, 17g5.com
            <a href={ finsightWebUrl.getOurProductsUrl() } target="_blank"> & more </a>
          </>
        ) }
        footerContent={ <NeedAnAccount /> }
        showPreloader={ isSubmitting }
      >
        <FinalForm
          name={ LOGIN_FORM_NAME }
          dataTest={ LOGIN_FORM_NAME }
          onSubmit={ handleSubmit }
          render={ renderFields }
          validate={ validate }
        />
      </UsersContent>
    </>
  );
};

export default (props) => (
  <LoginContext>
    <Login { ...props } />
  </LoginContext>
);
