import debounce from 'lodash/debounce';
import { domainRegexp } from '@/ui/shared/validators/validateDomain';
import SSORepository from '@/users/infrastructure/repository/SSORepository';
import Logger from '@/Framework/browser/log/Logger';
import { useDIContext } from '@/Framework/DI/DIContext';
import getRouter from '@/users/infrastructure/next/Router';
import { useCallback } from 'react';

const useSSOValidation = (isRegister = false): (value: string) => void => {
  const { container } = useDIContext();
  const { replace } = getRouter();

  const checkIsSSODomain = async (value: string) => {
    if (!value) return;
    const domain = value.split('@')[1];
    if (!domain || !domainRegexp.test(domain)) return;
    try {
      const resp = await container.get(SSORepository).checkIsSSOEmail({ domain });
      if (resp) {
        replace(isRegister ? `/register/sso/${ value }` : `/login/sso/${ value }`);
      }
    } catch (err) {
      Logger.error(err);
    }
  };

  return useCallback(debounce(checkIsSSODomain, 500), []);
};

export default useSSOValidation;
