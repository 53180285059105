import React from 'react';
import withGlobalProps from '@/Framework/Router/Next/withGlobalProps';
import Layout from '@/users/ui/common/Layout';
import LoginComponent from '@/users/ui/components/Login';
import { useSessionContext } from '@/users/application/Session/SessionContext';

export const getServerSideProps = withGlobalProps(() => ({ props: {} }));

const Login = () => (
  <Layout withBanner onInit={ useSessionContext().switchToPublicAccess }>
    <LoginComponent />
  </Layout>
);

export default Login;
