import React from 'react';
import { RetainQueryLink } from '@/ui/shared/components/Next/Link';

/**
 * @param {Object} props
 * @return {ReactElement}
 */
function NeedAnAccount() {
  return <span>Need an account? <RetainQueryLink href="/register">Register Now</RetainQueryLink></span>;
}

export default NeedAnAccount;
